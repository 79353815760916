<template>
  <div class="titlebloc">
    <h1>Génère ton QR Code sans te faire chier</h1>
    <span>Par Romain Lenoir</span>
  </div>

  <div>
    <div class="form-container">

      <!-- Contenu du QR Code -->
      <div class="form-section">
        <h2>Contenu du QR Code</h2>
        <div class="form-group">
          <label for="value">
            <span class="info-icon" data-tooltip="Entrez le texte ou l'URL à encoder dans le QR Code">ℹ️</span>
            Entrez le texte ou l'URL :
          </label>
          <input id="value" v-model="value" placeholder="Entrez l'URL ou le texte" class="full-width" />
        </div>
      </div>

      <!-- Style des Points -->
      <div class="form-section">
        <h2>Style des Points</h2>
        <div class="form-group">
          <label for="dotsStyle">
            <span class="info-icon" data-tooltip="Choisissez le style des points du QR Code">ℹ️</span>
            Style des Points :
          </label>
          <select id="dotsStyle" v-model="dotsStyle">
            <option value="square">Carré</option>
            <option value="rounded">Arrondi</option>
            <option value="dots">Points</option>
            <option value="classy">Classy</option>
            <option value="classy-rounded">Classy Arrondi</option>
            <option value="extra-rounded">Super Arrondi</option>
          </select>
        </div>

        <!-- Choix du Type de Couleur des Points -->
        <div class="form-group">
          <label for="dotsColorType">
            <span class="info-icon" data-tooltip="Choisissez le type de couleur pour les points">ℹ️</span>
            Type de Couleur des Points :
          </label>
          <div>
            <input type="radio" id="singleColor" value="single" v-model="dotsColorType" />
            <label for="singleColor">Couleur Unique</label>
            <input type="radio" id="gradientColor" value="gradient" v-model="dotsColorType" />
            <label for="gradientColor">Dégradé de Couleur</label>
          </div>
        </div>

        <!-- Point Single Color -->
        <div v-if="dotsColorType === 'single'" class="form-group">
          <label for="colorDark">
            <span class="info-icon" data-tooltip="Choisissez la couleur des points du QR Code">ℹ️</span>
            Couleur des Points :
          </label>
          <input id="colorDark" v-model="colorDark" type="color" />
        </div>

        <!-- Point Gradient Colors -->
        <div v-if="dotsColorType === 'gradient'" class="form-group">
          <label for="gradientColor1">
            <span class="info-icon" data-tooltip="Choisissez la première couleur du dégradé">ℹ️</span>
            Couleur Dégradée 1 :
          </label>
          <input id="gradientColor1" v-model="gradientColor1" type="color" />

          <label for="gradientColor2">
            <span class="info-icon" data-tooltip="Choisissez la deuxième couleur du dégradé">ℹ️</span>
            Couleur Dégradée 2 :
          </label>
          <input id="gradientColor2" v-model="gradientColor2" type="color" />

          <label for="gradientType">
            <span class="info-icon" data-tooltip="Choisissez le type de dégradé">ℹ️</span>
            Type de Dégradé :
          </label>
          <select id="gradientType" v-model="gradientType">
            <option value="linear">Linéaire</option>
            <option value="radial">Radial</option>
          </select>

          <label for="gradientRotation">
            <span class="info-icon" data-tooltip="Choisissez la rotation du dégradé en radians">ℹ️</span>
            Rotation du Dégradé :
          </label>
          <input id="gradientRotation" v-model.number="gradientRotation" type="range" min="0" max="6.28" step="0.01" />
          <span>{{ gradientRotation }} radians</span>
        </div>
      </div>

      <!-- Style des Coins -->
      <div class="form-section">
        <h2>Style des Coins</h2>
        <div class="form-group">
          <label for="cornerStyle">
            <span class="info-icon" data-tooltip="Choisissez le style des coins du QR Code">ℹ️</span>
            Style des Coins :
          </label>
          <select id="cornerStyle" v-model="cornerStyle">
            <option value="square">Carré</option>
            <option value="dot">Point</option>
            <option value="extra-rounded">Super Arrondi</option>
          </select>
        </div>

        <!-- Choix du Type de Couleur des Coins -->
        <div class="form-group">
          <label for="cornerColorType">
            <span class="info-icon" data-tooltip="Choisissez le type de couleur pour les coins">ℹ️</span>
            Type de Couleur des Coins :
          </label>
          <div>
            <input type="radio" id="singleCornerColor" value="single" v-model="cornerColorType" />
            <label for="singleCornerColor">Couleur Unique</label>
            <input type="radio" id="gradientCornerColor" value="gradient" v-model="cornerColorType" />
            <label for="gradientCornerColor">Dégradé de Couleur</label>
          </div>
        </div>

        <!-- Corner Single Color -->
        <div v-if="cornerColorType === 'single'" class="form-group">
          <label for="cornerColor">
            <span class="info-icon" data-tooltip="Choisissez la couleur des coins du QR Code">ℹ️</span>
            Couleur des Coins :
          </label>
          <input id="cornerColor" v-model="cornerColor" type="color" />
        </div>

        <!-- Corner Gradient Colors -->
        <div v-if="cornerColorType === 'gradient'" class="form-group">
          <label for="cornerGradientColor1">
            <span class="info-icon" data-tooltip="Choisissez la première couleur du dégradé des coins">ℹ️</span>
            Couleur Dégradée des Coins 1 :
          </label>
          <input id="cornerGradientColor1" v-model="cornerGradientColor1" type="color" />

          <label for="cornerGradientColor2">
            <span class="info-icon" data-tooltip="Choisissez la deuxième couleur du dégradé des coins">ℹ️</span>
            Couleur Dégradée des Coins 2 :
          </label>
          <input id="cornerGradientColor2" v-model="cornerGradientColor2" type="color" />

          <label for="cornerGradientType">
            <span class="info-icon" data-tooltip="Choisissez le type de dégradé pour les coins">ℹ️</span>
            Type de Dégradé des Coins :
          </label>
          <select id="cornerGradientType" v-model="cornerGradientType">
            <option value="linear">Linéaire</option>
            <option value="radial">Radial</option>
          </select>

          <label for="cornerGradientRotation">
            <span class="info-icon" data-tooltip="Choisissez la rotation du dégradé des coins en radians">ℹ️</span>
            Rotation du Dégradé des Coins :
          </label>
          <input id="cornerGradientRotation" v-model.number="cornerGradientRotation" type="range" min="0" max="6.28" step="0.01" />
          <span>{{ cornerGradientRotation }} radians</span>
        </div>
      </div>

      <!-- Style du Fond -->
      <div class="form-section">
        <h2>Style du Fond</h2>
        
        <!-- Choix du Type de Fond -->
        <div class="form-group">
          <label for="backgroundType">
            <span class="info-icon" data-tooltip="Choisissez le type de fond pour le QR Code">ℹ️</span>
            Type de Fond :
          </label>
          <div>
            <input type="radio" id="backgroundColor" value="color" v-model="backgroundType" />
            <label for="backgroundColor">Couleur Unie</label>
            <input type="radio" id="backgroundGradient" value="gradient" v-model="backgroundType" />
            <label for="backgroundGradient">Dégradé</label>
            <input type="radio" id="backgroundTransparent" value="transparent" v-model="backgroundType" />
            <label for="backgroundTransparent">Transparent</label>
          </div>
        </div>

        <!-- Fond Couleur Unie -->
        <div v-if="backgroundType === 'color'" class="form-group">
          <label for="backgroundColorSingle">
            <span class="info-icon" data-tooltip="Choisissez la couleur de fond unie pour le QR Code">ℹ️</span>
            Couleur du Fond :
          </label>
          <input id="backgroundColorSingle" v-model="backgroundColorSingle" type="color" />
        </div>

        <!-- Fond Dégradé -->
        <div v-if="backgroundType === 'gradient'" class="form-group">
          <label for="backgroundGradientColor1">
            <span class="info-icon" data-tooltip="Choisissez la première couleur du dégradé de fond">ℹ️</span>
            Couleur Dégradée 1 :
          </label>
          <input id="backgroundGradientColor1" v-model="backgroundGradientColor1" type="color" />

          <label for="backgroundGradientColor2">
            <span class="info-icon" data-tooltip="Choisissez la deuxième couleur du dégradé de fond">ℹ️</span>
            Couleur Dégradée 2 :
          </label>
          <input id="backgroundGradientColor2" v-model="backgroundGradientColor2" type="color" />

          <label for="backgroundGradientType">
            <span class="info-icon" data-tooltip="Choisissez le type de dégradé de fond">ℹ️</span>
            Type de Dégradé :
          </label>
          <select id="backgroundGradientType" v-model="backgroundGradientType">
            <option value="linear">Linéaire</option>
            <option value="radial">Radial</option>
          </select>

          <label for="backgroundGradientRotation">
            <span class="info-icon" data-tooltip="Choisissez la rotation du dégradé de fond en radians">ℹ️</span>
            Rotation du Dégradé :
          </label>
          <input id="backgroundGradientRotation" v-model.number="backgroundGradientRotation" type="range" min="0" max="6.28" step="0.01" />
          <span>{{ backgroundGradientRotation }} radians</span>
        </div>
      </div>

      <!-- Image Centrale -->
      <div class="form-section">
        <h2>Image au Centre</h2>
        <div class="form-group">
          <label for="logo">
            <span class="info-icon" data-tooltip="Choisissez une image à ajouter au centre du QR Code">ℹ️</span>
            Image :
          </label>
          <input id="logo" type="file" @change="onImageChange" />
        </div>

        <div class="form-group">
          <label for="hideBackgroundDots">
            <span class="info-icon" data-tooltip="Cacher les points de fond derrière l'image">ℹ️</span>
            Cacher les Points de Fond :
          </label>
          <input id="hideBackgroundDots" type="checkbox" v-model="hideBackgroundDots" />
        </div>

        <div class="form-group">
          <label for="imageSize">
            <span class="info-icon" data-tooltip="Définissez la taille de l'image (coefficient de la taille du QR Code, recommandé : inférieur à 0.5)">ℹ️</span>
            Taille de l'Image :
          </label>
          <input id="imageSize" v-model.number="imageSize" type="range" min="0.1" max="0.9" step="0.01" />
          <span>{{ imageSize * 100 }}%</span>
        </div>

        <div class="form-group">
          <label for="imageMargin">
            <span class="info-icon" data-tooltip="Définissez la marge autour de l'image">ℹ️</span>
            Marge de l'Image :
          </label>
          <input id="imageMargin" v-model.number="imageMargin" type="range" min="0" max="50" step="1" />
          <span>{{ imageMargin }} pixels</span>
        </div>
      </div>

      <!-- Autres Options -->
      <div class="form-section">
        <h2>Autres Options</h2>
        <div class="form-group">
          <label for="size">
            <span class="info-icon" data-tooltip="Définir la taille du QR Code en pixels (entre 50 et 550 pixels)">ℹ️</span>
            Taille du QR Code :
          </label>
          <input id="size" v-model.number="size" type="range" min="50" max="550" @input="validateSize" />
          <span>{{ size }} pixels</span>
        </div>

        <div class="form-group">
          <label for="margin">
            <span class="info-icon" data-tooltip="Définir la marge autour du QR Code (minimum 0 pixels)">ℹ️</span>
            Marge du QR Code :
          </label>
          <input id="margin" v-model.number="margin" type="range" min="0" max="50" step="1" />
          <span>{{ margin }} pixels</span>
        </div>

        <div class="form-group">
          <label for="level">
            <span class="info-icon" data-tooltip="Choisissez le niveau de correction d'erreurs du QR Code">ℹ️</span>
            Niveau de Correction d'Erreurs :
          </label>
          <select id="level" v-model="level">
            <option value="L">Faible (L) - ~7% correction</option>
            <option value="M">Moyen (M) - ~15% correction</option>
            <option value="Q">Quartile (Q) - ~25% correction</option>
            <option value="H">Haut (H) - ~30% correction</option>
          </select>
        </div>

        <div class="form-group">
          <label for="renderAs">
            <span class="info-icon" data-tooltip="Choisissez le format de rendu (PNG ou SVG)">ℹ️</span>
            Rendu en tant que :
          </label>
          <select id="renderAs" v-model="renderAs">
            <option value="canvas">PNG</option>
            <option value="svg">SVG</option>
          </select>
        </div>
      </div>

      <button class="download-button full-width" @click="downloadQRCode">Télécharger le QR Code</button>
    </div>

    <div ref="qrCodeContainer" class="qr-code-container"></div>
    <div ref="qrCodePreview" class="qr-code-preview"></div>
  </div>
</template>

<script>
import QRCodeStyling from "qr-code-styling";

export default {
  name: 'App',
  data() {
    return {
      value: 'https://example.com',
      size: 200,
      colorDark: '#000000',
      backgroundType: 'color',
      backgroundColorSingle: '#ffffff',
      backgroundGradientColor1: '#ffffff',
      backgroundGradientColor2: '#000000',
      backgroundGradientType: 'linear',
      backgroundGradientRotation: 0,
      margin: 4,
      level: 'H',
      renderAs: 'canvas',
      dotsStyle: 'square',
      dotsColorType: 'single',
      gradientColor1: '#000000',
      gradientColor2: '#ffffff',
      gradientType: 'linear',
      gradientRotation: 0,
      cornerStyle: 'square',
      cornerColorType: 'single',
      cornerColor: '#000000',
      cornerGradientColor1: '#000000',
      cornerGradientColor2: '#ffffff',
      cornerGradientType: 'linear',
      cornerGradientRotation: 0,
      logo: null,
      hideBackgroundDots: true,
      imageSize: 0.4,
      imageMargin: 0,
      qrCode: null
    };
  },
  mounted() {
    this.$nextTick(() => {
        this.generateQRCode();
    });
  },
  watch: {
    value: 'generateQRCode',
    size: 'generateQRCode',
    colorDark: 'generateQRCode',
    backgroundType: 'generateQRCode',
    backgroundColorSingle: 'generateQRCode',
    backgroundGradientColor1: 'generateQRCode',
    backgroundGradientColor2: 'generateQRCode',
    backgroundGradientType: 'generateQRCode',
    backgroundGradientRotation: 'generateQRCode',
    margin: 'generateQRCode',
    level: 'generateQRCode',
    renderAs: 'generateQRCode',
    dotsStyle: 'generateQRCode',
    dotsColorType: 'generateQRCode',
    gradientColor1: 'generateQRCode',
    gradientColor2: 'generateQRCode',
    gradientType: 'generateQRCode',
    gradientRotation: 'generateQRCode',
    cornerStyle: 'generateQRCode',
    cornerColorType: 'generateQRCode',
    cornerColor: 'generateQRCode',
    cornerGradientColor1: 'generateQRCode',
    cornerGradientColor2: 'generateQRCode',
    cornerGradientType: 'generateQRCode',
    cornerGradientRotation: 'generateQRCode',
    logo: 'generateQRCode',
    hideBackgroundDots: 'generateQRCode',
    imageSize: 'generateQRCode',
    imageMargin: 'generateQRCode',
  },
  methods: {
    generateQRCode() {
      const dotsOptions = {
        type: this.dotsStyle,
        color: this.dotsColorType === 'single' ? this.colorDark : undefined,
        gradient: this.dotsColorType === 'gradient' ? {
          type: this.gradientType,
          rotation: this.gradientRotation,
          colorStops: [
            { offset: 0, color: this.gradientColor1 },
            { offset: 1, color: this.gradientColor2 }
          ]
        } : undefined
      };

      const cornersSquareOptions = {
        type: this.cornerStyle,
        color: this.cornerColorType === 'single' ? this.cornerColor : undefined,
        gradient: this.cornerColorType === 'gradient' ? {
          type: this.cornerGradientType,
          rotation: this.cornerGradientRotation,
          colorStops: [
            { offset: 0, color: this.cornerGradientColor1 },
            { offset: 1, color: this.cornerGradientColor2 }
          ]
        } : undefined
      };

      const backgroundOptions = this.backgroundType === 'color'
        ? { color: this.backgroundColorSingle }
        : this.backgroundType === 'gradient'
        ? {
            gradient: {
              type: this.backgroundGradientType,
              rotation: this.backgroundGradientRotation,
              colorStops: [
                { offset: 0, color: this.backgroundGradientColor1 },
                { offset: 1, color: this.backgroundGradientColor2 }
              ]
            }
          }
        : { color: 'transparent' };

      const qrCodeConfig = {
        width: this.size,
        height: this.size,
        data: this.value,
        image: this.logo,
        imageOptions: {
          hideBackgroundDots: this.hideBackgroundDots,
          imageSize: this.imageSize,
          margin: this.imageMargin // Marge de l'image
        },
        dotsOptions: dotsOptions,
        cornersSquareOptions: cornersSquareOptions,
        backgroundOptions: backgroundOptions,
        qrOptions: {
          errorCorrectionLevel: this.level
        },
        margin: this.margin // Marge du QR code
      };

      // Mise à jour du QR code principal
      if (this.qrCode) {
        this.qrCode.update(qrCodeConfig);
      } else {
        this.qrCode = new QRCodeStyling(qrCodeConfig);
        this.qrCode.append(this.$refs.qrCodeContainer);
      }

      // Mise à jour de la petite prévisualisation
      this.updateQrCodePreview(qrCodeConfig);
    },

    updateQrCodePreview(qrCodeConfig) {
      if (this.$refs.qrCodePreview) {
        const qrCodePreview = new QRCodeStyling({
          ...qrCodeConfig,
          width: 100,
          height: 100,
        });

        this.$refs.qrCodePreview.innerHTML = ""; // Nettoyer le conteneur de la prévisualisation
        qrCodePreview.append(this.$refs.qrCodePreview);
      } else {
        console.error("qrCodePreview n'est pas défini !");
      }
    }
    ,
    onImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.logo = URL.createObjectURL(file);
      }
    },
    validateSize() {
      if (this.size < 50) {
        this.size = 50;
      } else if (this.size > 550) {
        this.size = 550;
      }
    },
    validateMargin() {
      if (this.margin < 0) {
        this.margin = 0;
      }
    },
    downloadQRCode() {
      this.qrCode.download({
        name: "qr-code",
        extension: this.renderAs === "svg" ? "svg" : "png"
      });
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  min-height: 100vh;
  background-color: #ffdee9;
  background-image: linear-gradient(315deg, #ffdee9 0%, #b5fffc 100%);
}

.titlebloc {
  margin: 20px 20px 50px 20px;
}

* {
  font-family: "Roboto", sans-serif;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  margin-top: 60px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.2);
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-section {
  position: relative;
  margin-bottom: 50px;
  width: 100%;
  padding-bottom: 10px; /* Pour faire de la place au pseudo-élément */
}

.form-section::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px; /* Épaisseur de la "bordure" */
  background: linear-gradient(to right, transparent, rgb(87, 87, 87), transparent);
}

.form-section h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

.form-group {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="color"],
.form-group select {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.form-group input.full-width,
.download-button.full-width {
  width: 100%; /* S'assure que l'input texte et le bouton prennent toute la largeur disponible */
}

.radio-group {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10px;
}

.radio-group label {
  margin-left: 5px;
}

.download-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #32c1fa;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #f96596;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.qr-code-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Styles pour la prévisualisation du QR code */
.qr-code-preview {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 100px;
  height: 100px;
  background-color: transparent; /* Permet de voir la transparence */
  z-index: 1000;
  border: 1px solid #ccc;
}


.info-icon {
  cursor: help;
  margin-right: 5px;
  font-size: 16px;
  color: #007bff;
  vertical-align: middle;
  position: relative;
}

.info-icon::before {
  content: attr(data-tooltip);
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  font-size: 12px;
  width: max-content;
  z-index: 10;
}

.info-icon:hover::before {
  opacity: 1;
}
</style>
